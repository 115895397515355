.icon {
  display: block;
  width: 26px;
  height: 26px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon--colored {
  filter: invert(51%) sepia(61%) saturate(3927%) hue-rotate(350deg)
    brightness(98%) contrast(89%);
}
.icon--academy {
  background-image: url("/assets/icons/academy.svg");
}
.icon--reports {
  background-image: url("/assets/icons/reports.svg");
}
.icon--pie-chart {
  background-image: url("/assets/icons/pie-chart.svg");
}
.icon--click {
  background-image: url("/assets/icons/click.svg");
}
.icon--overview {
  background-image: url("/assets/icons/Overview.svg");
}
.icon--clock {
  background-image: url("/assets/icons/clock.svg");
}
.icon--eye {
  background-image: url("/assets/icons/eye.svg");
}
.icon--activity {
  background-image: url("/assets/icons/activity.svg");
}
.icon--download {
  background-image: url("/assets/icons/download.svg");
}
.icon--search {
  background-image: url("/assets/icons/search.svg");
}
.icon--newAcademy {
  background-image: url("/assets/icons/newAcademy.svg");
}
.icon--ads {
  background-image: url("/assets/icons/ads.svg");
}
.icon--coach {
  background-image: url("/assets/icons/coach.svg");
}
.icon--interactions {
  background-image: url("/assets/icons/interactions.svg");
}
.icon--video {
  background-image: url("/assets/icons/video.svg");
}
.icon--money {
  background-image: url("/assets/icons/money.svg");
}
.icon--waiting {
  background-image: url("/assets/icons/waiting.svg");
}
.icon--invoice {
  background-image: url("/assets/icons/invoice.svg");
}
.icon--invoice_green {
  background-image: url("/assets/icons/invoice_green.svg");
}
.icon--invoice_orange {
  background-image: url("/assets/icons/invoice_orange.svg");
}
.icon--invoice_red {
  background-image: url("/assets/icons/invoice_red.svg");
}
.icon--group {
  background-image: url("/assets/icons/group.svg");
}
.icon--group_orange {
  background-image: url("/assets/icons/group_orange.svg");
}
.icon--card {
  background-image: url("/assets/icons/id-card.svg");
}
.icon--soccer {
  background-image: url("/assets/icons/soccer.svg");
}
.icon--tour {
  background-image: url("/assets/icons/tour.svg");
}
.icon--training {
  background-image: url("/assets/icons/training.svg");
}
.icon--minus {
  background-image: url("/assets/icons/minys.svg");
}
.icon--increase {
  background-image: url("/assets/icons/ic_Down.svg");
}
