/* Colors */
/* Fonts */
/* Black and bold by default */
@import url(./assets/styles/icons.scss);
body {
  display: block;
  width: auto;
  height: auto !important;
  overflow: visible;
  color: #202020;
  font-family: "circular-bold";
  background: #efefef;
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

/* INPUT Fields */
.input {
  width: 100%;
  padding: 25px 28px;
  border: 0;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  background-color: #abafb3;
  color: #32582c;
  font-size: 23px;
  display: block;
}
.input::placeholder {
  color: #32582c;
  opacity: 0.6;
}
.input:focus {
  outline: none;
  background-color: #fff;
}

/* Backgrounds */
.bg--orange {
  background-color: #ed6425;
}

.bg--orange--10 {
  background-color: rgba(237, 100, 37, 0.1);
}

/* BUTTONS */
.btn {
  border-radius: 10px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.btn-primary {
  background-color: #ed6425 !important;
  border-color: #ed6425 !important;
}

/* ICONS */
/* OTHERS */
.hyperLink {
  text-decoration: underline;
  font-family: "circular-regular";
  color: #ed6425;
  cursor: pointer;
}
.hyperLink:hover {
  color: #ed6425;
  filter: contrast(2);
}

/* Fonts */
@font-face {
  font-family: "circular-bold";
  src: local("CircularStd"), url(./assets/fonts/CircularStd-Bold.otf) format("truetype");
}
@font-face {
  font-family: "circular-regular";
  src: local("CircularStd"), url(./assets/fonts/CircularStd-Medium.otf) format("truetype");
}
@font-face {
  font-family: "circular-light";
  src: local("CircularStd"), url(./assets/fonts/CircularStd-Light.otf) format("truetype");
}
html,
body {
  font-family: "circular-regular";
}

.mat-drawer-container {
  height: 100%;
  background-color: transparent;
}

.mat-drawer-content {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.row {
  margin: 0;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: #ed6623 !important;
  border-color: #ed6623 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ed6623 !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}