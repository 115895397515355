/* Colors */
$black: #202020;
$green: #32582c;
$orange: #ed6425;
$lightOrange: #955e2a;
$lightGrey: #f4f5f6;
$darkGrey: #464a53;
$successColor: #56c662;
$errorColor: #d30000;
$lightGrey: #abafb3;
$lightGreen: #4caf50;

/* Fonts */
$bold: "circular-bold";
$regular: "circular-regular";
$light: "circular-light";
