@import "variables";

/* Black and bold by default */
body {
  display: block;
  width: auto;
  height: auto !important;
  overflow: visible;
  color: $black;
  font-family: $bold;
  background: #efefef;
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

/* INPUT Fields */
.input {
  width: 100%;
  padding: 25px 28px;
  border: 0;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  background-color: $lightGrey;
  color: $green;
  font-size: 23px;
  display: block;

  &::placeholder {
    color: $green;
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    background-color: #fff;
  }
}

/* Backgrounds */
.bg--orange {
  background-color: $orange;
}

.bg--orange--10 {
  background-color: rgba($color: $orange, $alpha: 0.1);
}

/* BUTTONS */
.btn {
  border-radius: 10px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.btn-primary {
  background-color: $orange !important;
  border-color: $orange !important;
}

/* ICONS */
@import url(./assets/styles/icons.scss);

/* OTHERS */
.hyperLink {
  text-decoration: underline;
  font-family: $regular;
  color: $orange;
  cursor: pointer;

  &:hover {
    color: $orange;
    filter: contrast(2);
  }
}

/* Fonts */
@font-face {
  font-family: "circular-bold";
  src: local("CircularStd"),
    url(./assets/fonts/CircularStd-Bold.otf) format("truetype");
}

@font-face {
  font-family: "circular-regular";
  src: local("CircularStd"),
    url(./assets/fonts/CircularStd-Medium.otf) format("truetype");
}

@font-face {
  font-family: "circular-light";
  src: local("CircularStd"),
    url(./assets/fonts/CircularStd-Light.otf) format("truetype");
}

html,
body {
  font-family: "circular-regular";
  // height: 100% !important;
}

.mat-drawer-container {
  height: 100%;
  background-color: transparent;
}

.mat-drawer-content {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.row {
  margin: 0;
}

// radio button
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: #ed6623 !important;
  border-color: #ed6623 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ed6623 !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}
